import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { TextField, IconButton, Chip, Stack } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTheme } from '@mui/material/styles';

export default function ExtraRecipients({ formik }) {
	const [email, setEmail] = useState('');
	const theme = useTheme();

	const handleAddEmail = async () => {
		if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && !formik.values.ccRecipients.includes(email)) {
			formik.setFieldValue('ccRecipients', [...formik.values.ccRecipients, email]);
			setEmail('');
		}
	};

	const handleDeleteEmail = (emailToDelete) => {
		formik.setFieldValue(
			'ccRecipients',
			formik.values.ccRecipients.filter((email) => email !== emailToDelete),
		);
	};

	return (
		<Stack spacing={2}>
			<Grid size={{ md: 6 }}>
				<Stack direction="row" spacing={1} alignItems="center">
					<TextField
						id="ccRecipients"
						name="ccRecipients"
						label="Extra Recipients"
						variant="standard"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						onKeyDown={(e) => e.key === 'Enter' && handleAddEmail()} // Add on Enter key
						fullWidth
					/>
					<IconButton
						color="primary"
						sx={{ p: '10px' }}
						aria-label="directions"
						onClick={handleAddEmail}
						disabled={formik.isSubmitting}
					>
						<AddCircleOutlineIcon />
					</IconButton>
				</Stack>
			</Grid>

			<Grid size={{ md: 12 }}>
				<Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }} useFlexGap>
					{formik.values.ccRecipients.map((email) => (
						<Chip
							key={email}
							label={email}
							onDelete={() => handleDeleteEmail(email)}
							sx={{ color: theme.palette.grey[900] }}
						/>
					))}
				</Stack>
			</Grid>
		</Stack>
	);
}
