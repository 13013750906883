import * as Yup from 'yup';

const Test = {
	schema: Yup.object().shape({
		name: Yup.string().default('').required('Required'),
		desc: Yup.string().default('').nullable(),
		price: Yup.number().positive().default(0).required('Required'),
	}),
};

Test.isFlatTravelFee = (id) => {
	return Number(id) === Number(process.env.REACT_APP_PES_FLAT_TRAVEL_FEE_TEST_ID);
};

export default Test;
