import * as Yup from 'yup';

const Supplier = {
	schema: Yup.object().shape({
		name: Yup.string().default('').required('Required'),
		phone: Yup.string()
			.default('')
			.nullable()
			.transform((value) => {
				if (value === null) {
					return '';
				}
				return value;
			}),
		email: Yup.string().email('Invalid email').default(''),
		address: Yup.string().default('').required('Required'),
		tests: Yup.array().default([]),
		ccRecipients: Yup.array().default([]),
	}),
};

Supplier.formatTestsPayload = (tests) =>
	tests.map((test) => {
		return Number(test.id);
	});

Supplier.isOnsiteTesting = (id) => {
	return Number(id) === Number(process.env.REACT_APP_PES_ONSITE_TESTING_TESTING_CENTRE_ID);
};

export default Supplier;
