import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardActions, Paper, Stack, LinearProgress } from '@mui/material';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { useLazyGetRequestQuery, usePayRequestMutation } from '../slices/api';
import SuccessAlert from '../components/alert';
import RequestDetail from '../sections/@dashboard/request/RequestDetail';
import RequestReports from './RequestReports';
import Request from '../utils/request';

const RequestView = ({ isUser, backwardRoute }) => {
	const { reqId } = useParams();
	const [searchParam] = useSearchParams();
	const navigate = useNavigate();
	const [paymentSuccessAlertOpen, setPaymentSuccessAlertOpen] = useState(false);
	const [payRequest, { isSuccess, isLoading, data }] = usePayRequestMutation();
	const [trigger, result] = useLazyGetRequestQuery(reqId);

	useEffect(() => {
		if (isSuccess && data) {
			window.location = data.url;
		}
	}, [isSuccess, data]);

	useEffect(() => {
		let timer;

		if (searchParam.get('from') === 'payment_success') {
			timer = setTimeout(() => {
				console.log('lazy load after 5 seconds');
				setPaymentSuccessAlertOpen(true);
				trigger(reqId);
			}, 5000);
		} else {
			trigger(reqId);
		}

		return () => clearTimeout(timer);
	}, [reqId, trigger, searchParam]);

	if (result.isLoading || !result.data) {
		return <LinearProgress />;
	}

	const request = Request.cast(result.data);
	const { tests } = request;

	const onPayRequest = () => {
		payRequest({
			requestId: request.id,
			items: tests.map((test) => ({
				id: test.id,
			})),
		});
	};

	return (
		<>
			<Helmet>
				<title> Request Detail - {process.env.REACT_APP_ORG} </title>
			</Helmet>

			<SuccessAlert
				open={paymentSuccessAlertOpen}
				title={'Thank you for your payment!'}
				subtitle={'We will take it from there. Look out for a scheduling email.'}
				onClose={() => {
					setPaymentSuccessAlertOpen(false);
				}}
			/>

			<Paper elevation={6}>
				<Card>
					<CardActions sx={{ m: 1 }}>
						<Stack spacing={2} direction="row">
							{request.isReadyForPayment && (
								<Button
									loading={isLoading}
									variant="outlined"
									color="primary"
									onClick={onPayRequest}
									disabled={isSuccess}
								>
									<span>Pay Now</span>
								</Button>
							)}
							<Button variant="outlined" color="error" onClick={() => navigate(backwardRoute)}>
								Back
							</Button>
						</Stack>
					</CardActions>
					<RequestDetail request={request} hideTnC hideTestTotal />
					<RequestReports request={request} isUser={isUser} />
				</Card>
			</Paper>
		</>
	);
};

export default RequestView;
