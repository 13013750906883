import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import isPast from 'date-fns/isPast';
import startOfToday from 'date-fns/startOfToday';

// @mui
import Grid from '@mui/material/Grid2';
import {
	Box,
	Card,
	Chip,
	Table,
	Stack,
	Paper,
	Button,
	Popover,
	TableRow,
	LinearProgress,
	MenuItem,
	NativeSelect,
	TableBody,
	TableCell,
	Container,
	Typography,
	IconButton,
	TableContainer,
	TablePagination,
} from '@mui/material';

// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import Dialog from '../components/dialog';
import Status from '../components/request';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import Request from '../utils/request';
import { fDateTime } from '../utils/formatTime';

import {
	useGetRequestsQuery,
	useDelRequestMutation,
	useUpdateRequestAssessmentDateMutation,
	useUpdateRequestMutation,
} from '../slices/api';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
	{ id: 'id', label: 'ID', align: 'left' },
	{ id: 'name', label: 'Name', align: 'left' },
	{ id: 'email', label: 'Email', align: 'left' },
	{ id: 'phone', label: 'Phone', align: 'left' },
	{ id: 'employer', label: 'Employer', align: 'left' },
	{ id: 'assessmentDate', label: 'Assessment Date', align: 'left' },
	{ id: 'status', label: 'Status', align: 'center' },
	{ id: 'edit', label: 'Edit', align: 'left', disableSort: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_request) => _request.candidateName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

export default function Requests({ isSupplier, isUser, isAdmin }) {
	/*
		requests querying, sorting, pagination and miscel
	*/
	const { data: requests = [], isFetching } = useGetRequestsQuery();

	const [menuOpen, setMenuOpen] = useState(null);
	const [requestEditable, setRequestEditable] = useState(false);
	const [page, setPage] = useState(0);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const [filterName, setFilterName] = useState('');
	const [selected] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const theme = useTheme();
	const navigate = useNavigate();
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;
	const filteredRequests = applySortFilter(requests, getComparator(order, orderBy), filterName);
	const isNotFound = !filteredRequests.length && !!filterName;

	/*
		request deletion
	*/
	const [selectedRequest, setSelectedRequest] = useState({});
	const [delRequest, { isSuccess: isDelReqSuccess, isLoading: isDelReqLoading }] = useDelRequestMutation();
	const [requestDeleteConfirmDialogOpen, setRequestDeleteConfirmDialogOpen] = useState(null);

	/*
		request assessment date editing
	*/
	const [assessmentDateDialogOpen, setAssessmentDateDialogOpen] = useState(null);
	const [assessmentDate, setAssessmentDate] = useState(null);
	const [updateAssessmentDate, { isSuccess: isUpdateAssessmentDateSuccess, isLoading: isUpdateAssessmentDateLoading }] =
		useUpdateRequestAssessmentDateMutation();

	const handleOpenAssessmentDateDialog = (event, selectedRequest) => {
		const date = isPast(new Date(selectedRequest.assessmentDate))
			? startOfToday()
			: new Date(selectedRequest.assessmentDate);
		setAssessmentDate(date);
		setSelectedRequest(selectedRequest);
		setAssessmentDateDialogOpen(true);
	};

	const handleCloseAssessmentDateDialog = () => {
		setAssessmentDateDialogOpen(null);
	};

	const onAssessmentDateChange = (newValue) => {
		setAssessmentDate(newValue);
	};

	const onAssessmentDateUpdate = () => {
		updateAssessmentDate({
			id: selectedRequest.id,
			assessmentDate,
		});
	};

	/*
		request status editing
	*/
	const [statusEditingDialogOpen, setStatusEditingDialogOpen] = useState(null);
	const [updateRequest, { isSuccess: isUpdateReqStatusSuccess, isLoading: isUpdateReqStatusLoading }] =
		useUpdateRequestMutation();

	let statusOptions = [];

	if (isAdmin) {
		statusOptions = Request.getAllowedEditableStatusAdmin();
	} else if (isSupplier) {
		statusOptions = Request.getAllowedEditableStatusSupplier();
	}

	const handleOpenStatusEditingDialog = (event, selectedRequest) => {
		if (Request.isStatusEditingAllowed(selectedRequest.status)) {
			setStatusEditingDialogOpen(true);
			setSelectedRequest(selectedRequest);
		}
	};

	const onStatusChange = (e) => {
		if (!statusOptions.includes(e.target.value)) {
			return;
		}

		setSelectedRequest({
			...selectedRequest,
			status: e.target.value,
		});
	};

	const onStatusUpdate = () => {
		const { id, status } = selectedRequest;
		updateRequest({
			id,
			status,
		});
	};

	const handleCloseStatusEditingDialog = () => {
		setStatusEditingDialogOpen(null);
	};

	const handleOpenMenu = (event, selectedRequest) => {
		setMenuOpen(event.currentTarget);
		setSelectedRequest(selectedRequest);
		setRequestEditable(Request.isEditable(selectedRequest.status));
	};

	const handleCloseMenu = () => {
		setMenuOpen(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(Number.parseInt(event.target.value, 10));
	};

	const handleFilterByName = (event) => {
		setPage(0);
		setFilterName(event.target.value);
	};

	const handleEditRequest = () => {
		navigate(`/dashboard/requests/${selectedRequest.id}/edit`);
		setMenuOpen(null);
	};

	const handleOpenRequestDetail = () => {
		navigate(`/dashboard/requests/${selectedRequest.id}/view`);
		setMenuOpen(null);
	};

	/*
		del request
	*/
	const handleDeleteRequest = () => {
		setRequestDeleteConfirmDialogOpen(true);
		setMenuOpen(null);
	};

	const handleCloseRequestDeleteConfirmDialog = () => {
		setRequestDeleteConfirmDialogOpen(null);
	};

	const onRequestDeleteConfirm = () => {
		delRequest(selectedRequest.id);
	};

	useEffect(() => {
		if (isUpdateAssessmentDateSuccess) {
			setAssessmentDateDialogOpen(null);
			navigate('/dashboard/requests');
		}

		if (isDelReqSuccess) {
			setRequestDeleteConfirmDialogOpen(null);
		}

		if (isUpdateReqStatusSuccess) {
			setStatusEditingDialogOpen(null);
			navigate('/dashboard/requests');
		}
	}, [isDelReqSuccess, isUpdateReqStatusSuccess, isUpdateAssessmentDateSuccess, navigate]);

	return (
		<>
			<Helmet>
				<title> Requests - {process.env.REACT_APP_ORG} </title>
			</Helmet>
			<Container
				sx={{
					maxWidth: '100%',
					// width: {
					//   xs: '100%',
					//   sm: '90%',
					//   md: '80%',
					//   lg: '90%',
					//   padding: 2
					// }
				}}
			>
				<Stack spacing={4} mb={4}>
					<Typography variant="landing_page_heading" gutterBottom>
						Request
					</Typography>
					{!isSupplier && !isFetching && (
						<Box>
							<Button
								variant="contained"
								onClick={() => navigate('/dashboard/requests/new')}
								startIcon={<Iconify icon="eva:plus-fill" />}
							>
								New Request
							</Button>
						</Box>
					)}
				</Stack>

				{isFetching ? (
					<LinearProgress />
				) : (
					<>
						<Card>
							<UserListToolbar
								numSelected={selected.length}
								filterName={filterName}
								onFilterName={handleFilterByName}
								placeHolderText={'Candidate name...'}
							/>

							<Scrollbar>
								<TableContainer>
									<Table>
										<UserListHead
											order={order}
											orderBy={orderBy}
											headLabel={TABLE_HEAD}
											numSelected={selected.length}
											onRequestSort={handleRequestSort}
										/>
										<TableBody>
											{filteredRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((request) => {
												const { candidateName, candidate, status, assessmentDate, id } = request;

												const { email, employer, phone } = candidate;

												let assessmentDateView = null;
												let statusEditingView = null;

												if (isUser) {
													if (assessmentDate) {
														assessmentDateView = (
															<Chip
																label={fDateTime(assessmentDate)}
																color="primary"
																variant="outlined"
																onClick={(event) => handleOpenAssessmentDateDialog(event, { id, assessmentDate })}
																disabled={isUser || Request.disableAssessmentSchedulling(status)}
															/>
														);
													}
												} else {
													assessmentDateView = (
														<Chip
															label={assessmentDate ? fDateTime(assessmentDate) : 'Select a Date'}
															color="primary"
															variant="outlined"
															onClick={(event) => handleOpenAssessmentDateDialog(event, { id, assessmentDate })}
															disabled={isUser || Request.disableAssessmentSchedulling(status)}
														/>
													);
												}

												if (isUser) {
													statusEditingView = <Status status={status} />;
												} else {
													statusEditingView = (
														<Status
															status={status}
															clickable
															onClick={(event) => handleOpenStatusEditingDialog(event, { id, status })}
														/>
													);
												}

												return (
													<TableRow
														hover
														key={id}
														tabIndex={-1}
														role="checkbox"
														selected={selected.indexOf(candidateName) !== -1}
													>
														<TableCell align="left">
															<Typography variant="subtitle2">{id}</Typography>
														</TableCell>
														<TableCell align="left">{candidateName}</TableCell>
														<TableCell align="left">{email}</TableCell>
														<TableCell align="left">{phone}</TableCell>
														<TableCell align="left">{employer}</TableCell>
														<TableCell
															align="left"
															sx={{
																':hover': {
																	cursor: 'pointer',
																	color: theme.palette.primary.main,
																},
															}}
														>
															{assessmentDateView}
														</TableCell>
														<TableCell>{statusEditingView}</TableCell>
														<TableCell>
															<IconButton
																size="large"
																color="inherit"
																onClick={(event) => handleOpenMenu(event, { id, assessmentDate, status })}
															>
																<Iconify icon={'eva:more-vertical-fill'} />
															</IconButton>
														</TableCell>
													</TableRow>
												);
											})}
											{emptyRows > 0 && (
												<TableRow style={{ height: 53 * emptyRows }}>
													<TableCell colSpan={6} />
												</TableRow>
											)}
										</TableBody>

										{isNotFound && (
											<TableBody>
												<TableRow>
													<TableCell align="center" colSpan={6} sx={{ py: 3 }}>
														<Paper
															sx={{
																textAlign: 'center',
															}}
														>
															<Typography variant="h6" paragraph>
																Not found
															</Typography>

															<Typography variant="body2">
																No results found for &nbsp;
																<strong>&quot;{filterName}&quot;</strong>.
																<br /> Check your typos or use complete words
															</Typography>
														</Paper>
													</TableCell>
												</TableRow>
											</TableBody>
										)}
									</Table>
								</TableContainer>
							</Scrollbar>

							<TablePagination
								rowsPerPageOptions={[10, 20, 50, 100]}
								component="div"
								count={requests.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Card>

						{/* request menu options popover */}
						<Popover
							open={Boolean(menuOpen)}
							anchorEl={menuOpen}
							onClose={handleCloseMenu}
							anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
							transformOrigin={{ vertical: 'top', horizontal: 'right' }}
							PaperProps={{
								sx: {
									p: 1,
									width: 140,
									'& .MuiMenuItem-root': {
										px: 1,
										typography: 'body2',
										borderRadius: 0.75,
									},
								},
							}}
						>
							<MenuItem onClick={handleOpenRequestDetail}>
								<Iconify icon={'eva:file-text-outline'} sx={{ mr: 1 }} />
								View
							</MenuItem>

							{isSupplier || !requestEditable ? null : (
								<MenuItem onClick={handleEditRequest}>
									<Iconify icon={'eva:edit-fill'} sx={{ mr: 1 }} />
									Edit
								</MenuItem>
							)}

							{/* prevent users deleting their own unfinished requests whether inadvertently or not */}
							{(isSupplier || isUser) ? null : (
								<MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteRequest}>
									<Iconify icon={'eva:trash-2-outline'} sx={{ mr: 1 }} />
									Delete
								</MenuItem>
							)}
						</Popover>

						{/* assessment date editing */}
						<Dialog
							open={Boolean(assessmentDateDialogOpen)}
							onClose={handleCloseAssessmentDateDialog}
							title={`Choose an assessment date/time for request ${selectedRequest.id}`}
						>
							<Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
								<Grid>
									<DateTimePicker
										value={assessmentDate}
										onChange={onAssessmentDateChange}
										ampm
										format={'dd MMM yyyy p'}
									/>
								</Grid>
								<Grid>
									<Typography variant="subtitle2" sx={{ color: theme.palette.grey[600] }}>
										By confirming the assessment date/time, an confirmation email will be sent to the candidate. Please
										double check the date/time selected.
									</Typography>
								</Grid>
								<Grid>
									<Button
										loading={isUpdateAssessmentDateLoading}
										variant="outlined"
										color="primary"
										onClick={onAssessmentDateUpdate}
									>
										<span>Confirm</span>
									</Button>
								</Grid>
								<Grid>
									<Button color="error" variant="outlined" onClick={handleCloseAssessmentDateDialog}>
										Cancel
									</Button>
								</Grid>
							</Grid>
						</Dialog>

						{/* status editing */}
						<Dialog
							open={Boolean(statusEditingDialogOpen)}
							onClose={handleCloseStatusEditingDialog}
							title={`Change request ${selectedRequest.id} status`}
						>
							<Grid container spacing={2}>
								<Grid size={{ md: 12 }}>
									<NativeSelect
										defaultValue={selectedRequest.status}
										inputProps={{
											name: 'status',
											id: 'uncontrolled-native',
											onChange: onStatusChange,
										}}
									>
										{['--- Please Select ---', ...statusOptions].map((status, index) => (
											<option key={index} value={status}>
												{status}
											</option>
										))}
									</NativeSelect>
								</Grid>
								<Grid size={{ md: 6 }}>
									<Button
										loading={isUpdateReqStatusLoading}
										variant="outlined"
										color="primary"
										onClick={onStatusUpdate}
									>
										<span>Confirm</span>
									</Button>
								</Grid>
								<Grid size={{ md: 6 }}>
									<Button color="error" variant="outlined" onClick={handleCloseStatusEditingDialog}>
										Cancel
									</Button>
								</Grid>
							</Grid>
						</Dialog>

						{/* request delete confirm */}
						<Dialog
							open={Boolean(requestDeleteConfirmDialogOpen)}
							onClose={handleCloseRequestDeleteConfirmDialog}
							title={'Warning'}
						>
							<Grid container spacing={2}>
								<Grid size={{ md: 12 }}>
									<Typography variant="subtitle2">{`Confirm to delete request ${selectedRequest.id}`}</Typography>
								</Grid>
								<Grid size={{ md: 6 }}>
									<Button loading={isDelReqLoading} variant="outlined" color="primary" onClick={onRequestDeleteConfirm}>
										<span>Confirm</span>
									</Button>
								</Grid>
								<Grid size={{ md: 6 }}>
									<Button color="error" variant="outlined" onClick={handleCloseRequestDeleteConfirmDialog}>
										Cancel
									</Button>
								</Grid>
							</Grid>
						</Dialog>
					</>
				)}
			</Container>
		</>
	);
}
