import React, { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFormik, getIn } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Button, Card, LinearProgress, CardContent, MenuItem, TextField, Typography, Box } from '@mui/material';

import { state } from '../utils/config';
import { Suppliers, Tests, Status } from '../sections/@dashboard/request';
import Request from '../utils/request';
import { useGetRequestQuery, useUpdateRequestMutation, useCancelRequestMutation } from '../slices/api';
import Toast from '../components/toast';

export default function EditRequest({ isUser, isAdmin, isSupplier }) {
	const { reqId } = useParams();
	const navigate = useNavigate();
	const [
		updateRequest,
		{ isError: isUpdateRequestError, isLoading: isUpdateRequestLoading, isSuccess: isUpdateRequestSuccess },
	] = useUpdateRequestMutation();
	const [
		cancelRequest,
		{ isLoading: isCancelRequestLoading, isSuccess: isCancelRequestSuccess, isError: isCancelRequestError },
	] = useCancelRequestMutation();

	const { data: request } = useGetRequestQuery(reqId);

	const formik = useFormik({
		initialValues: useMemo(() => {
			if (request) {
				return Request.loadSchema().cast(
					{
						...request,
						supplierId: request.supplier.id,
					},
					{
						stripUnknown: true,
					},
				);
			}

			return Request.loadSchema().cast();
		}, [request]),
		enableReinitialize: true,
		validationSchema: Request.loadSchema('EDIT'),
		onSubmit: (values) => {
			updateRequest(values);
		},
	});

	useEffect(() => {
		if (isUpdateRequestError) {
			formik.setSubmitting(false);
		}
	}, [formik, isUpdateRequestError]);

	const onCancelRequest = () => {
		const message = Request.isAssessmentScheduled(request.status)
			? 'Are you sure you want to cancel? A 50% fee applies to appointments cancelled within 24 hours of the scheduled time.'
			: 'Are you sure you want to cancel it?';
		const isConfirmed = window.confirm(message);

		if (isConfirmed) {
			cancelRequest({
				id: request.id,
			});
		}
	};

	const disableActionButton = () => {
		return isUpdateRequestLoading || isCancelRequestLoading || !Request.isEditable(request.status);
	};

	if (!request) {
		return <LinearProgress />;
	}

	return (
		<>
			<Helmet>
				<title> Edit Request - {process.env.REACT_APP_ORG} </title>
			</Helmet>

			{isUpdateRequestSuccess && (
				<Toast body={'Request has been updated successfully!'} severity="success" autoHideDuration={3000} />
			)}

			{isUpdateRequestError && (
				<Toast body={'Failed to update your request!'} severity="error" autoHideDuration={3000} />
			)}

			{isCancelRequestSuccess && (
				<Toast body={'Request has been cancelled successfully!'} severity="success" autoHideDuration={3000} />
			)}

			{isCancelRequestError && (
				<Toast body={'Failed to cancel your request!'} severity="error" autoHideDuration={3000} />
			)}

			<Grid container justifyContent="start">
				<Grid size={{ md: 6 }}>
					<Typography variant="h4" gutterBottom>
						Edit Request
					</Typography>
					<Card md={{ m: 2 }}>
						<CardContent>
							<form onSubmit={formik.handleSubmit}>
								<Grid container spacing={2}>
									<Grid size={{ md: 12 }} container spacing={2} alignItems="center">
										<Grid size={{ md: 6 }}>
											<Box sx={{ display: 'flex', flexDirection: 'column' }}>
												<Typography variant="body1" sx={{ mb: 1 }}>
													Request ID
												</Typography>
												<Typography color="primary">{request.id}</Typography>
											</Box>
										</Grid>
										<Grid size={{ md: 6 }}>
											<Status formik={formik} showFormLabel isAdmin={isAdmin} isUser={isUser} isSupplier={isSupplier} />
										</Grid>
									</Grid>
									<Grid size={{ md: 6 }}>
										<TextField
											fullWidth
											margin="dense"
											id="firstname"
											name="candidate.firstname"
											label="First Name"
											variant="standard"
											value={formik.values.candidate.firstname}
											onChange={formik.handleChange}
											error={Boolean(
												getIn(formik.touched, 'candidate.firstname') && getIn(formik.errors, 'candidate.firstname'),
											)}
											helperText={
												getIn(formik.touched, 'candidate.firstname') && getIn(formik.errors, 'candidate.firstname')
											}
										/>
									</Grid>
									<Grid size={{ md: 6 }}>
										<TextField
											fullWidth
											margin="dense"
											id="lastname"
											name="candidate.lastname"
											label="Last Name"
											variant="standard"
											value={formik.values.candidate.lastname}
											onChange={formik.handleChange}
											error={Boolean(
												getIn(formik.touched, 'candidate.lastname') && getIn(formik.errors, 'candidate.lastname'),
											)}
											helperText={
												getIn(formik.touched, 'candidate.lastname') && getIn(formik.errors, 'candidate.lastname')
											}
										/>
									</Grid>
									<Grid size={{ md: 6 }}>
										<TextField
											fullWidth
											margin="dense"
											id="phone"
											name="candidate.phone"
											label="Phone"
											variant="standard"
											inputProps={{ inputMode: 'numeric', pattern: '^0[45]\\d{8}$', maxLength: 10 }}
											value={formik.values.candidate.phone}
											onChange={formik.handleChange}
											error={Boolean(
												getIn(formik.touched, 'candidate.phone') && getIn(formik.errors, 'candidate.phone'),
											)}
											helperText={getIn(formik.touched, 'candidate.phone') && getIn(formik.errors, 'candidate.phone')}
										/>
									</Grid>
									<Grid size={{ md: 6 }}>
										<TextField
											fullWidth
											margin="dense"
											id="email"
											name="candidate.email"
											label="Email"
											variant="standard"
											type="email"
											value={formik.values.candidate.email}
											onChange={formik.handleChange}
											error={Boolean(
												getIn(formik.touched, 'candidate.email') && getIn(formik.errors, 'candidate.email'),
											)}
											helperText={getIn(formik.touched, 'candidate.email') && getIn(formik.errors, 'candidate.email')}
										/>
									</Grid>
									<Grid size={{ md: 6 }}>
										<TextField
											fullWidth
											margin="dense"
											id="addrline"
											name="candidate.addrLine"
											label="Address"
											variant="standard"
											value={formik.values.candidate.addrLine}
											onChange={formik.handleChange}
											error={Boolean(
												getIn(formik.touched, 'candidate.addrLine') && getIn(formik.errors, 'candidate.addrLine'),
											)}
											helperText={
												getIn(formik.touched, 'candidate.addrLine') && getIn(formik.errors, 'candidate.addrLine')
											}
										/>
									</Grid>
									<Grid size={{ md: 6 }}>
										<TextField
											fullWidth
											margin="dense"
											id="addrsuburb"
											name="candidate.addrSuburb"
											label="Suburb"
											variant="standard"
											value={formik.values.candidate.addrSuburb}
											// onBlur={onSuburbBlur}
											onChange={formik.handleChange}
											error={Boolean(
												getIn(formik.touched, 'candidate.addrSuburb') && getIn(formik.errors, 'candidate.addrSuburb'),
											)}
											helperText={
												getIn(formik.touched, 'candidate.addrSuburb') && getIn(formik.errors, 'candidate.addrSuburb')
											}
										/>
									</Grid>
									<Grid size={{ md: 6 }}>
										<TextField
											fullWidth
											margin="dense"
											id="addrpostcode"
											name="candidate.addrPostcode"
											label="Postcode"
											variant="standard"
											inputProps={{ inputMode: 'numeric', maxLength: 4 }}
											value={formik.values.candidate.addrPostcode}
											onChange={formik.handleChange}
											error={Boolean(
												getIn(formik.touched, 'candidate.addrPostcode') &&
													getIn(formik.errors, 'candidate.addrPostcode'),
											)}
											helperText={
												getIn(formik.touched, 'candidate.addrPostcode') &&
												getIn(formik.errors, 'candidate.addrPostcode')
											}
										/>
									</Grid>
									<Grid size={{ md: 6 }}>
										<TextField
											select
											fullWidth
											margin="dense"
											id="addrstate"
											name="candidate.addrState"
											label="State"
											variant="standard"
											value={formik.values.candidate.addrState}
											onChange={formik.handleChange}
											error={Boolean(
												getIn(formik.touched, 'candidate.addrState') && getIn(formik.errors, 'candidate.addrState'),
											)}
											helperText={
												getIn(formik.touched, 'candidate.addrState') && getIn(formik.errors, 'candidate.addrState')
											}
										>
											{state.map((s) => (
												<MenuItem key={s.name} value={s.label}>
													{s.label}
												</MenuItem>
											))}
										</TextField>
									</Grid>
									<Grid size={{ md: 12 }}>
										<TextField
											fullWidth
											margin="dense"
											id="employer"
											name="candidate.employer"
											label="Employer"
											variant="standard"
											value={formik.values.candidate.employer}
											onChange={formik.handleChange}
											error={Boolean(
												getIn(formik.touched, 'candidate.employer') && getIn(formik.errors, 'candidate.employer'),
											)}
											helperText={
												getIn(formik.touched, 'candidate.employer') && getIn(formik.errors, 'candidate.employer')
											}
										/>
									</Grid>
									<Grid size={{ md: 12 }}>
										<Tests formik={formik} showFormLabel />
									</Grid>
									<Grid size={{ md: 12 }}>
										<Suppliers formik={formik} requestEdit />
									</Grid>
									<Grid size={{ md: 6 }}>
										<Grid container justifyContent="start" spacing={4}>
											<Grid>
												<Button
													loading={isUpdateRequestLoading}
													variant="outlined"
													color="primary"
													type="submit"
													disabled={disableActionButton()}
												>
													<span>Update</span>
												</Button>
											</Grid>
											<Grid>
												<Button color="error" variant="outlined" onClick={() => navigate('/dashboard/requests')}>
													Back
												</Button>
											</Grid>
										</Grid>
									</Grid>
									{Request.isCancellable(request.status) && (
										<Grid size={{ md: 6 }}>
											<Grid container justifyContent="flex-end">
												<Grid>
													<Button
														loading={isCancelRequestLoading}
														variant="outlined"
														color="error"
														disabled={disableActionButton()}
														onClick={onCancelRequest}
													>
														<span>Cancel Request</span>
													</Button>
												</Grid>
											</Grid>
										</Grid>
									)}
								</Grid>
							</form>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
