import React, { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, LinearProgress, CardContent, TextField, Typography, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Test from '../../utils/test';
import { useGetTestQuery, useUpdateTestMutation } from '../../slices/api';

export default function TestEdit() {
	const { testId } = useParams();
	const navigate = useNavigate();
	const [updateTest, { isSuccess, isError }] = useUpdateTestMutation();

	const { data: test, isLoading: isLoadingTest } = useGetTestQuery(testId);

	const formik = useFormik({
		initialValues: useMemo(() => {
			return Test.schema.cast({
				...test,
			});
		}, [test]),
		enableReinitialize: true,
		validationSchema: Test.schema,
		onSubmit: (values) => {
			updateTest(values);
		},
	});

	useEffect(() => {
		if (isSuccess) {
			navigate('/dashboard/admin/tests');
		}
		if (isError) {
			formik.setSubmitting(false);
		}
	}, [formik, isError, isSuccess, navigate]);

	if (isLoadingTest) {
		return <LinearProgress />;
	}

	return (
		<>
			<Helmet>
				<title> Edit Test - {process.env.REACT_APP_ORG} </title>
			</Helmet>
			<Grid container justifyContent="start">
				<Grid size={{ md: 6 }}>
					<Typography variant="h4" gutterBottom>
						Edit Test {testId}
					</Typography>
					<Card md={{ m: 2 }}>
						<CardContent>
							<form onSubmit={formik.handleSubmit}>
								<Grid container spacing={2}>
									<Grid size={{ md: 12 }}>
										<TextField
											fullWidth
											margin="dense"
											id="name"
											name="name"
											label="Name"
											variant="standard"
											value={formik.values.name}
											onChange={formik.handleChange}
											error={formik.touched.name && Boolean(formik.errors.name)}
											helperText={formik.touched.name && formik.errors.name}
										/>
									</Grid>
									<Grid size={{ md: 12 }}>
										<TextField
											fullWidth
											margin="dense"
											id="desc"
											name="desc"
											label="Description"
											variant="standard"
											value={formik.values.desc}
											onChange={formik.handleChange}
											error={formik.touched.desc && Boolean(formik.errors.desc)}
											helperText={formik.touched.desc && formik.errors.desc}
										/>
									</Grid>
									<Grid size={{ md: 12 }}>
										<TextField
											fullWidth
											margin="dense"
											id="price"
											name="price"
											label="Price"
											variant="standard"
											type="number"
											value={formik.values.price}
											onChange={formik.handleChange}
											error={formik.touched.price && Boolean(formik.errors.price)}
											helperText={formik.touched.price && formik.errors.price}
											InputProps={{
												startAdornment: <InputAdornment position="start">$</InputAdornment>,
											}}
										/>
									</Grid>

									<Grid size={{ md: 4 }}>
										<Grid container justifyContent="start" spacing={4}>
											<Grid size={{ md: 6 }}>
												<Button loading={formik.isSubmitting} variant="outlined" color="primary" type="submit">
													<span>Update</span>
												</Button>
											</Grid>
											<Grid size={{ md: 6 }}>
												<Button color="error" variant="outlined" onClick={() => navigate('/dashboard/admin/tests')}>
													Cancel
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</form>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
