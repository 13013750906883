import * as Yup from 'yup';
import convert from 'convert-units';
import { addDays, getDay } from 'date-fns';

import { fileMime } from './config';

export const status = {
	readyForPayment: 'READY_FOR_PAYMENT',
	readyForSchedule: 'READY_FOR_SCHEDULE',
	readyForAssessment: 'READY_FOR_ASSESSMENT',
	readyForApproval: 'READY_FOR_APPROVAL',
	completed: 'COMPLETED',
	cancelled: 'CANCELLED',
};

const cancellableStatus = [status.readyForPayment, status.readyForSchedule, status.readyForAssessment];
const editableStatus = [status.readyForPayment, status.readyForSchedule, status.readyForAssessment];

const Request = {};

Request.acceptedReportFileType = [fileMime.pdf, fileMime.doc, fileMime.img].join(', ');

const candidateSchema = Yup.object({
	firstname: Yup.string().default('').required('Required'),
	lastname: Yup.string().default('').required('Required'),
	employer: Yup.string().default('').nullable(true),
	phone: Yup.string().min(10).max(10).default('').required('Required'),
	email: Yup.string().email('Invalid email').default('').required('Required'),
	addrLine: Yup.string().default('').trim().required('Required'),
	addrState: Yup.string().default('').trim().required('Required'),
	addrSuburb: Yup.string().default('').trim().required('Required'),
	addrPostcode: Yup.string().default('').trim().required('Required'),
});

const fullSchema = Yup.object().shape({
	id: Yup.string().default(''),
	assessmentDate: Yup.date().nullable(true).default(null),
	// for users to choose a preferred date when creating a request
	prefAssessmentDate: Yup.date()
		// default to next week day
		.default(() => {
			const today = new Date();
			const dayOfWeek = getDay(today);
			let daysToAdd = 1;

			// If it's Saturday (6), add 2 days to get to Monday
			if (dayOfWeek === 6) {
				daysToAdd = 2;
			}
			// If it's Sunday (0), add 1 day to get to Monday
			else if (dayOfWeek === 0) {
				daysToAdd = 1;
			}

			return addDays(today, daysToAdd);
		})
		.required('Please select a preferred assessment date.'),
	status: Yup.string().default('READY_FOR_PAYMENT'),
	supplierId: Yup.string().default('').required('Please select a test centre.'),
	tests: Yup.array().default([]).min(1).required(),
	candidate: candidateSchema,
	tncAccepted: Yup.boolean()
		.oneOf([true], 'You must accept the terms and conditions.')
		.default(false)
		.required('You must accept the terms and conditions.'),
});

Request.loadSchema = (subset) => {
	if (subset === 'CANDIDATE_PERSONAL_INFO_CONTACT') {
		return Yup.object({
			candidate: candidateSchema.pick(['firstname', 'lastname', 'phone', 'email', 'employer']),
		});
	}

	if (subset === 'CANDIDATE_ADDRESS') {
		return Yup.object({
			candidate: candidateSchema.pick(['addrLine', 'addrState', 'addrSuburb', 'addrPostcode']),
		});
	}

	if (subset === 'TESTS') {
		return fullSchema.pick(['tests']);
	}

	if (subset === 'SUPPLIER') {
		return fullSchema.pick(['supplierId']);
	}

	if (subset === 'PREFERRED_ASSESSMENT_DATE') {
		return fullSchema.pick(['prefAssessmentDate']);
	}

	if (subset === 'REVIEW') {
		return fullSchema.pick(['tncAccepted']);
	}

	if (subset === 'EDIT') {
		return fullSchema.omit(['tncAccepted']);
	}

	return fullSchema;
};

Request.normalizeAddress = (addrLine, addrSuburb, addrState, addrPostcode) => {
	return `${addrLine} ${addrSuburb} ${addrState} ${addrPostcode}`;
};

Request.isAddressValid = (addrLine, addrSuburb, addrState, addrPostcode) => {
	try {
		fullSchema.validateSyncAt('candidate.addrLine', {
			candidate: {
				addrLine,
			},
		});

		fullSchema.validateSyncAt('candidate.addrSuburb', {
			candidate: {
				addrSuburb,
			},
		});

		fullSchema.validateSyncAt('candidate.addrState', {
			candidate: {
				addrState,
			},
		});

		fullSchema.validateSyncAt('candidate.addrPostcode', {
			candidate: {
				addrPostcode,
			},
		});

		return true;
	} catch (err) {
		return false;
	}
};

Request.ifMarkTestChecked =
	(requestedTests = []) =>
	(test = {}) => {
		return requestedTests.findIndex((t) => t.id.toString() === test.id.toString()) > -1;
	};

Request.resolveCoordinates = (coordinates = {}) => {
	const [lng, lat] = coordinates.coordinates;

	return {
		lng,
		lat,
	};
};

Request.formatSupplierDistance = (distanceInMeters) => {
	return `${convert(distanceInMeters).from('m').to('km').toFixed(2)}km`;
};

Request.getReportName = (s3ObjectKey) => {
	return s3ObjectKey.split('/').at(-1);
};

Request.getInitValues = (schema) => {
	if (schema === 'REVIEW') {
		return fullSchema.pick(['tncAccepted']).default();
	}

	return {};
};

// remove custom cast and use another way - see how setting supplier role in user edit is handled
Request.cast = (params) => {
	const req = Request.loadSchema().cast(params);
	const isReadyForPayment = req.status === status.readyForPayment;

	return {
		isReadyForPayment,
		...req,
	};
};

Request.disableAssessmentSchedulling = (currentStatus) => {
	return [status.readyForApproval, status.cancelled, status.completed].includes(currentStatus);
};

Request.isCancellable = (currentStatus) => {
	return cancellableStatus.includes(currentStatus);
};

Request.isAssessmentScheduled = (currentStatus) => {
	return status.readyForAssessment === currentStatus;
};

Request.isEditable = (currentStatus) => {
	return editableStatus.includes(currentStatus);
};

Request.isStatusEditingAllowed = (currentStatus) => {
	return ![status.completed, status.cancelled].includes(currentStatus);
};

Request.getAllowedEditableStatusAdmin = () => {
	return [
		status.readyForPayment,
		status.readyForSchedule,
		status.readyForAssessment,
		status.readyForApproval,
		status.completed,
	];
};

Request.getAllowedEditableStatusSupplier = () => {
	return [status.readyForApproval];
};

Request.disableTestsEditing = (currentStatus) => {
	return [
		status.readyForSchedule,
		status.readyForAssessment,
		status.readyForApproval,
		status.cancelled,
		status.completed,
	].includes(currentStatus);
};

export default Request;
