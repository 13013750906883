import { StaticDatePicker } from '@mui/x-date-pickers';
import { Box, Alert } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { fIsWeekend } from '../../../utils/formatTime';

const PreferredAssessmentDate = ({ formik }) => {
	const onDateTimeChange = (val) => {
		formik.setFieldValue('prefAssessmentDate', val);
	};

	const disableToday = (date) => {
		return fIsWeekend(date);
	};

	return (
		<Box>
			<Alert severity="info">
				We'll try our best to schedule your preferred date. Appointments are typically completed within 3 business days.
			</Alert>
			<Grid container justifyContent={'center'}>
				<Grid size={{ md: 6 }}>
					<StaticDatePicker
						defaultValue={formik.values.prefAssessmentDate}
						onChange={onDateTimeChange}
						shouldDisableDate={disableToday}
						disablePast
						slots={{ actionBar: () => null }}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PreferredAssessmentDate;
