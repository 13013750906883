import { Stack, Box, CircularProgress, Typography } from '@mui/material';

import Logo from '../components/logo';

export default function PostLogInLoadingPage() {
	return (
		<>
			<Stack
				direction="column"
				spacing={32}
				sx={{
					justifyContent: 'center',
					alignItems: 'center',
					mt: 4,
				}}
			>
				<Box sx={{ '& img': { width: 216, height: 'auto' } }}>
					<Logo disabledLink />
				</Box>

				<Box display="flex" flexDirection="column" alignItems="center">
					<CircularProgress size={64} sx={{ color: '#ff9015' }} />
					<Typography variant="h6" sx={{ mt: 4 }}>
						Just a moment...
					</Typography>
					<Typography variant="h6">We're setting up your account.</Typography>
				</Box>
			</Stack>
		</>
	);
}
