import React, { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Editor from 'react-simple-code-editor';
import { useFormik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Button, Card, LinearProgress, CardContent, TextField, FormControl, FormHelperText } from '@mui/material';
import { highlight, languages } from 'prismjs/components/prism-core';

import { useTheme } from '@mui/material/styles';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';

import './EmailTemplateStyle.css';

import EmailTemplate from '../../utils/emailTemplate';
import { useGetEmailTemplateQuery, useUpdateEmailTemplateMutation } from '../../slices/api';

export default function EmailTemplateEdit() {
	const { emailTemplateId } = useParams();
	const navigate = useNavigate();
	const theme = useTheme();
	const [updateEmailTemplate, { isSuccess, isError }] = useUpdateEmailTemplateMutation();

	const { data: emailTemplate, isLoading: isLoadingEmailTemplate } = useGetEmailTemplateQuery(emailTemplateId);

	const formik = useFormik({
		initialValues: useMemo(() => {
			if (emailTemplate) {
				return EmailTemplate.schema.cast({
					...emailTemplate,
				});
			}

			return EmailTemplate.schema.cast();
		}, [emailTemplate]),
		enableReinitialize: true,
		validationSchema: EmailTemplate.schema,
		onSubmit: (values) => {
			updateEmailTemplate(values);
		},
	});

	useEffect(() => {
		if (isSuccess) {
			navigate('/dashboard/admin/email-templates');
		}
		if (isError) {
			formik.setSubmitting(false);
		}
	}, [formik, isSuccess, isError, navigate]);

	if (isLoadingEmailTemplate) {
		return <LinearProgress />;
	}

	return (
		<>
			<Helmet>
				<title> Edit Email Template - {process.env.REACT_APP_ORG} </title>
			</Helmet>
			<Grid container justifyContent="start">
				<Grid size={{ md: 6 }}>
					<Card md={{ m: 2 }}>
						<CardContent>
							<form onSubmit={formik.handleSubmit}>
								<Grid container spacing={2}>
									<Grid size={{ md: 12 }}>
										<TextField
											fullWidth
											margin="dense"
											id="name"
											name="name"
											label="Name"
											variant="standard"
											value={formik.values.name}
											onChange={formik.handleChange}
											error={formik.touched.name && Boolean(formik.errors.name)}
											helperText={formik.touched.name && formik.errors.name}
										/>
									</Grid>
									<Grid size={{ md: 12 }}>
										<TextField
											fullWidth
											margin="dense"
											id="subject"
											name="subject"
											label="Subject"
											variant="standard"
											type="subject"
											value={formik.values.subject}
											onChange={formik.handleChange}
											error={formik.touched.subject && Boolean(formik.errors.subject)}
											helperText={formik.touched.subject && formik.errors.subject}
										/>
									</Grid>
									<Grid size={{ md: 12 }}>
										<FormControl fullWidth error={formik.touched.bodyHtml && Boolean(formik.errors.bodyHtml)}>
											<Editor
												id="bodyHtml"
												name="bodyHtml"
												value={formik.values.bodyHtml}
												onValueChange={(val) => formik.setFieldValue('bodyHtml', val)}
												highlight={(code) => highlight(code, languages.markup)}
												padding={10}
												style={{
													fontFamily: '"Fira code", "Fira Mono", monospace',
													fontSize: 12,
													border: `1.5px ${theme.palette.grey[400]} solid`,
													borderRadius: '4px',
												}}
												textareaClassName="email-template-editor"
											/>
											<FormHelperText sx={{ ml: 0 }}>
												{formik.touched.bodyHtml && formik.errors.bodyHtml}
											</FormHelperText>
										</FormControl>
									</Grid>
									<Grid size={{ md: 4 }}>
										<Grid container justifyContent="start" spacing={4}>
											<Grid size={{ md: 6 }}>
												<Button loading={formik.isSubmitting} variant="outlined" color="primary" type="submit">
													<span>Update</span>
												</Button>
											</Grid>
											<Grid size={{ md: 6 }}>
												<Button
													color="error"
													variant="outlined"
													onClick={() => navigate('/dashboard/admin/email-templates')}
												>
													Cancel
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</form>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
