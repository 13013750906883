import React, { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import {
	Button,
	Card,
	LinearProgress,
	CardContent,
	TextField,
	Typography,
	InputLabel,
	NativeSelect,
	FormControl,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import User from '../../utils/user';
import { useGetUserQuery, useGetRolesQuery, useUpdateUserMutation, useGetSuppliersQuery } from '../../slices/api';
import { selectSupplierRoleId } from '../../slices/roles';

export default function UserEdit() {
	const { userId } = useParams();
	const navigate = useNavigate();
	const [updateUser, { isSuccess, isError }] = useUpdateUserMutation();
	const theme = useTheme();

	const { data: user, isLoading: isLoadingUser } = useGetUserQuery(userId);
	const { data: roles, isLoading: isLoadingRoles } = useGetRolesQuery();
	const { data: suppliers, isLoading: isLoadingSupplier } = useGetSuppliersQuery();

	const supplierRoleId = useSelector((state) => selectSupplierRoleId(state));

	const formik = useFormik({
		initialValues: useMemo(() => {
			if (user) {
				return User.schema.cast({
					...user,
				});
			}

			return User.schema.cast();
		}, [user]),
		enableReinitialize: true,
		validationSchema: User.schema,
		onSubmit: (values) => {
			updateUser(User.normalizeForUpdate(values));
		},
	});

	const onRoleSelect = (e) => {
		if (e.target.value === supplierRoleId.toString()) {
			formik.setFieldValue('showSupplierDropDown', true);
		} else {
			formik.setFieldValue('showSupplierDropDown', false);
		}

		formik.setFieldValue('roleId', e.target.value);
	};

	const onSupplierSelect = (e) => {
		formik.setFieldValue('supplierId', e.target.value);
	};

	useEffect(() => {
		if (isSuccess) {
			navigate('/dashboard/admin/users');
		}

		if (isError) {
			formik.setSubmitting(false);
		}
	}, [formik, isSuccess, isError, navigate]);

	if (isLoadingUser || isLoadingRoles || isLoadingSupplier) {
		return <LinearProgress />;
	}

	return (
		<>
			<Helmet>
				<title> Edit User - {process.env.REACT_APP_ORG} </title>
			</Helmet>
			<Grid container justifyContent="start">
				<Grid size={{ md: 6 }}>
					<Typography variant="h4" gutterBottom>
						Edit User {userId}
					</Typography>
					<Card md={2}>
						<CardContent>
							<form onSubmit={formik.handleSubmit}>
								<Grid container spacing={2}>
									<Grid size={{ md: 12 }}>
										<TextField
											fullWidth
											margin="dense"
											id="firstname"
											name="firstname"
											label="First Name"
											variant="standard"
											value={formik.values.firstname}
											onChange={formik.handleChange}
											error={formik.touched.firstname && Boolean(formik.errors.firstname)}
											helperText={formik.touched.firstname && formik.errors.firstname}
										/>
									</Grid>
									<Grid size={{ md: 12 }}>
										<TextField
											fullWidth
											margin="dense"
											id="lastname"
											name="lastname"
											label="Last Name"
											variant="standard"
											value={formik.values.lastname}
											onChange={formik.handleChange}
											error={formik.touched.lastname && Boolean(formik.errors.lastname)}
											helperText={formik.touched.lastname && formik.errors.lastname}
										/>
									</Grid>
									<Grid size={{ md: 12 }}>
										<TextField
											fullWidth
											margin="dense"
											id="phone"
											name="phone"
											label="Phone"
											variant="standard"
											inputProps={{ inputMode: 'numeric', maxLength: 10 }}
											value={formik.values.phone}
											onChange={formik.handleChange}
											error={formik.touched.phone && Boolean(formik.errors.phone)}
											helperText={formik.touched.phone && formik.errors.phone}
										/>
									</Grid>

									<Grid size={{ md: 12 }}>
										<TextField
											fullWidth
											margin="dense"
											id="email"
											name="email"
											label="Email"
											variant="standard"
											type="email"
											value={formik.values.email}
											disabled
										/>
									</Grid>
									<Grid size={{ md: 12 }}>
										<TextField
											fullWidth
											margin="dense"
											id="company"
											name="company"
											label="Company"
											variant="standard"
											type="company"
											value={formik.values.company}
											onChange={formik.handleChange}
											error={formik.touched.company && Boolean(formik.errors.company)}
											helperText={formik.touched.company && formik.errors.company}
										/>
									</Grid>
									<Grid size={{ md: 12 }}>
										<FormControl>
											<InputLabel variant="standard" htmlFor="uncontrolled-native" required>
												Role
											</InputLabel>
											<NativeSelect
												value={formik.values.roleId}
												inputProps={{
													name: 'roleId',
													id: 'uncontrolled-native-role',
													onChange: onRoleSelect,
												}}
											>
												{roles.map((role) => (
													<option key={role.id} value={role.id}>
														{role.role}
													</option>
												))}
											</NativeSelect>
										</FormControl>
									</Grid>
									{formik.values.showSupplierDropDown && (
										<Grid size={{ md: 12 }}>
											<FormControl>
												<InputLabel
													variant="standard"
													htmlFor="uncontrolled-native"
													required
													error={Boolean(formik.touched.supplierId && formik.errors.supplierId)}
												>
													Supplier
												</InputLabel>
												<NativeSelect
													defaultValue={formik.values.supplierId || '--- Please Select ---'}
													inputProps={{
														name: 'supplierId',
														id: 'uncontrolled-native-supplier',
														onChange: onSupplierSelect,
													}}
												>
													<option key="hint">{'--- Please Select ---'}</option>
													{suppliers.map((supplier) => (
														<option key={supplier.id} value={supplier.id}>
															{supplier.name}
														</option>
													))}
												</NativeSelect>
											</FormControl>
										</Grid>
									)}
									<Grid size={{ md: 4 }}>
										<Grid container justifyContent="start" spacing={4}>
											<Grid size={{ md: 6 }}>
												<Button loading={formik.isSubmitting} variant="outlined" color="primary" type="submit">
													<span>Update</span>
												</Button>
											</Grid>
											<Grid size={{ md: 6 }}>
												<Button
													color="error"
													variant="outlined"
													disabled={formik.isSubmitting}
													onClick={() => navigate('/dashboard/admin/users')}
													sx={{
														'&.Mui-disabled': {
															color: theme.palette.error.light,
															borderColor: theme.palette.error.light,
														},
													}}
												>
													Cancel
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</form>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
