import { Card } from '@mui/material';

import { useGetSupplierQuery, useGetTestsQuery } from '../../../slices/api';
import RequestDetail from './RequestDetail';
import RequestDetailSkeleton from './RequestDetailSkeleton';

const Review = (props) => {
	const { formik } = props;
	const requestToReview = {};

	const { data: supplier } = useGetSupplierQuery(formik.values.supplierId);
	const { data: tests } = useGetTestsQuery();

	if (supplier) {
		requestToReview.supplier = supplier;
	}

	if (tests) {
		requestToReview.tests = tests.filter(
			(test) => formik.values.tests.findIndex((t) => t.id === test.id.toString()) > -1,
		);
	}

	if (formik.values.prefAssessmentDate) {
		requestToReview.prefAssessmentDate = formik.values.prefAssessmentDate;
	}

	requestToReview.candidate = {
		...formik.values.candidate,
	};

	return requestToReview.supplier && requestToReview.tests ? (
		<Card>
			<RequestDetail request={requestToReview} formik={formik} hideTestTotal />
		</Card>
	) : (
		<RequestDetailSkeleton />
	);
};

export default Review;
