import {
	Alert,
	FormControl,
	FormControlLabel,
	FormLabel,
	Checkbox,
	FormHelperText,
	FormGroup,
	Skeleton,
	Stack,
	Box,
	Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Request from '../../../utils/request';
import { useGetTestsQuery } from '../../../slices/api';

const Tests = ({ formik, showFormLabel = false, showHelpText = false, hidePricing = false }) => {
	const { data: tests } = useGetTestsQuery();
	const theme = useTheme();

	const ifTestChecked = Request.ifMarkTestChecked(formik.values.tests);

	const onTestChange = (e) => {
		const newValue = e.target.checked
			? [
					...formik.values.tests,
					{
						id: e.target.name,
					},
				]
			: formik.values.tests.filter((test) => Number(test.id) !== Number(e.target.name));

		formik.setValues({
			...formik.values,
			tests: newValue,
			// reset test centre when we change tests
			supplierId: '',
		});
	};

	const ifError = Boolean(formik.touched.tests) && Boolean(formik.errors.tests);

	const subtotal = tests
		? formik.values.tests.reduce((sum, selectedTest) => {
				const test = tests.find((test) => Number(test.id) === Number(selectedTest.id));
				return sum + (test ? test.price : 0);
			}, 0)
		: 0;

	return (
		<>
			{!tests ? (
				<Stack width={240} spacing={3}>
					<Skeleton variant="rectangular" animation="wave" height={18} />
					<Skeleton variant="rectangular" animation="wave" height={18} />
					<Skeleton variant="rectangular" animation="wave" height={18} />
					<Skeleton variant="rectangular" animation="wave" height={18} />
				</Stack>
			) : (
				<FormControl margin="normal" component="fieldset" variant="standard" error={ifError} sx={{ mt: 0 }}>
					{showHelpText && (
						<Alert severity="info" sx={{ mb: 2 }}>
							If you need help with test selection, please contact us at <a href="tel:1300391947">1300 391 947</a> or{' '}
							<a href="mailto:pes@ausrehab.com">pes@ausrehab.com</a>.
						</Alert>
					)}
					{showFormLabel && <FormLabel component="legend">Tests</FormLabel>}
					{ifError && <FormHelperText>{formik.errors.tests}</FormHelperText>}
					<FormGroup>
						{tests.map((test) => (
							<FormControlLabel
								key={test.id}
								control={
									<Checkbox
										name={test.id.toString()}
										checked={ifTestChecked(test)}
										onChange={onTestChange}
										disabled={Request.disableTestsEditing(formik.values.status)}
									/>
								}
								label={
									<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', minWidth: 320 }}>
										<span>{test.name}</span>
										{!hidePricing && <span>${test.price}</span>}
									</Box>
								}
							/>
						))}
					</FormGroup>

					{formik.values.tests.length > 0 && !hidePricing && (
						<Box
							sx={{
								justifyContent: 'space-between',
								minWidth: 320,
								mt: 2,
								pr: 2,
							}}
						>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
								<Typography sx={{ fontWeight: 'bold' }}>Subtotal</Typography>
								<Typography sx={{ color: theme.palette.primary.main }}>${subtotal}</Typography>
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
								<Typography sx={{ fontWeight: 'bold' }}>GST (10%)</Typography>
								<Typography sx={{ color: theme.palette.primary.main }}>${(subtotal * 0.1).toFixed(2)}</Typography>
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
								<Typography sx={{ fontWeight: 'bold' }}>Total (inc. GST)</Typography>
								<Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
									${(subtotal * 1.1).toFixed(2)}
								</Typography>
							</Box>
						</Box>
					)}
				</FormControl>
			)}
		</>
	);
};

export default Tests;
