import { useState } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert, AlertTitle, Typography } from '@mui/material';

const Toast = ({ onClose, body, title, severity, autoHideDuration = 10000 }) => {
	const [open, setOpen] = useState(true);

	const handleClose = () => {
		if (onClose !== undefined) {
			onClose();
		}
		setOpen(false);
	};

	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={open}
			onClose={handleClose}
			autoHideDuration={autoHideDuration}
		>
			<Alert severity={severity}>
				{title && <AlertTitle sx={{ typography: 'h6' }}>{title}</AlertTitle>}
				<Typography>{body}</Typography>
			</Alert>
		</Snackbar>
	);
};

Toast.propTypes = {
	onClose: PropTypes.func,
};

export default Toast;
