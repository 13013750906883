import { Stack, Box, Typography } from '@mui/material';

import Logo from '../components/logo';

export default function ErrorPage() {
	return (
		<>
			<Stack
				direction="column"
				spacing={32}
				sx={{
					justifyContent: 'center',
					alignItems: 'center',
					mt: 4,
				}}
			>
				<Box sx={{ '& img': { width: 216, height: 'auto' } }}>
					<Logo disabledLink />
				</Box>

				<Box display="flex" flexDirection="column" alignItems="center">
					<Typography variant="h5" sx={{ mb: 2 }}>
						Something doesn't look right!
					</Typography>
					<Typography variant="subtitle1">
						Please refresh the page. If error still persists, please contact system admin.
					</Typography>
				</Box>
			</Stack>
		</>
	);
}
