import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import {
	Card,
	Table,
	Stack,
	Paper,
	Button,
	Popover,
	TableRow,
	LinearProgress,
	MenuItem,
	TableBody,
	TableCell,
	Container,
	Typography,
	IconButton,
	TableContainer,
	TablePagination,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { fCurrency } from '../../utils/formatNumber';

import { useGetTestsQuery, useDelTestMutation } from '../../slices/api';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
	{ id: 'id', label: 'ID', align: 'left' },
	{ id: 'name', label: 'Name', align: 'left' },
	{ id: 'description', label: 'Description', align: 'left' },
	{ id: 'price', label: 'Price', align: 'left' },
	{ id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_test) => _test.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

export default function Tests() {
	const [menuOpen, setMenuOpen] = useState(null);
	const [page, setPage] = useState(0);
	const [order, setOrder] = useState('asc');
	const [selected] = useState([]);
	const [orderBy, setOrderBy] = useState('name');
	const [filterName, setFilterName] = useState('');
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [selectedTestId, setSelectedTestId] = useState(null);
	const navigate = useNavigate();
	const { data, isLoading } = useGetTestsQuery();
	const [delTestMutation] = useDelTestMutation();

	let emptyRows = [];
	let filteredData = [];
	let isNotFound = !filteredData.length && !!filterName;

	const handleOpenMenu = (event, id) => {
		setMenuOpen(event.currentTarget);
		setSelectedTestId(id);
	};

	const handleCloseMenu = () => {
		setMenuOpen(null);
	};

	const handleDataSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(Number.parseInt(event.target.value, 10));
	};

	const handleFilterByName = (event) => {
		setPage(0);
		setFilterName(event.target.value);
	};

	const handleDeleteTest = () => {
		delTestMutation(selectedTestId);
		setMenuOpen(null);
	};

	const handleEditTest = () => {
		navigate(`/dashboard/admin/tests/${selectedTestId}/edit`);
		setMenuOpen(null);
	};

	// const handleViewTemplate = () => {
	//   navigate(`/dashboard/admin/email-templates/${selectedTemplateId}/view`);
	//   setMenuOpen(null);
	// };

	if (isLoading) {
		return (
			<>
				<Helmet>
					<title>Tests - {process.env.REACT_APP_ORG}</title>
				</Helmet>
				<Container sx={{ width: '75%' }}>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Typography variant="h4" gutterBottom>
							Tests
						</Typography>
					</Stack>
					<LinearProgress />
				</Container>
			</>
		);
	}

	if (data) {
		emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
		filteredData = applySortFilter(data, getComparator(order, orderBy), filterName);
		isNotFound = !filteredData.length && !!filterName;
	}

	return (
		<>
			<Helmet>
				<title>Tests - {process.env.REACT_APP_ORG}</title>
			</Helmet>
			<Container sx={{ width: '75%' }}>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Typography variant="landing_page_heading" gutterBottom>
						Tests
					</Typography>
					<Button
						variant="contained"
						onClick={() => navigate('/dashboard/admin/tests/new')}
						startIcon={<Iconify icon="eva:plus-fill" />}
					>
						New Test
					</Button>
				</Stack>

				<Card>
					<UserListToolbar
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
						placeHolderText={'Test name...'}
					/>

					<Scrollbar>
						<TableContainer>
							<Table>
								<UserListHead
									order={order}
									orderBy={orderBy}
									headLabel={TABLE_HEAD}
									numSelected={selected.length}
									onRequestSort={handleDataSort}
								/>
								<TableBody>
									{filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
										const { id, name, desc, price } = row;

										return (
											<TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selected.indexOf(name) !== -1}>
												<TableCell align="left">
													<Typography variant="subtitle2">{id}</Typography>
												</TableCell>
												<TableCell align="left">{name}</TableCell>
												<TableCell align="left">{desc}</TableCell>
												<TableCell align="left">{fCurrency(price)}</TableCell>
												<TableCell align="right">
													<IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
														<Iconify icon={'eva:more-vertical-fill'} />
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
									{emptyRows > 0 && (
										<TableRow style={{ height: 53 * emptyRows }}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>

								{isNotFound && (
									<TableBody>
										<TableRow>
											<TableCell align="center" colSpan={6} sx={{ py: 3 }}>
												<Paper
													sx={{
														textAlign: 'center',
													}}
												>
													<Typography variant="h6" paragraph>
														Not found
													</Typography>

													<Typography variant="body2">
														No results found for &nbsp;
														<strong>&quot;{filterName}&quot;</strong>.
														<br /> Check your typos or use complete words
													</Typography>
												</Paper>
											</TableCell>
										</TableRow>
									</TableBody>
								)}
							</Table>
						</TableContainer>
					</Scrollbar>

					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={data ? data.length : 0}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Card>
			</Container>

			<Popover
				open={Boolean(menuOpen)}
				anchorEl={menuOpen}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				PaperProps={{
					sx: {
						p: 1,
						width: 140,
						'& .MuiMenuItem-root': {
							px: 1,
							typography: 'body2',
							borderRadius: 0.75,
						},
					},
				}}
			>
				<MenuItem onClick={handleEditTest}>
					<Iconify icon={'eva:edit-fill'} sx={{ mr: 1 }} />
					View/Edit
				</MenuItem>
				<MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteTest}>
					<Iconify icon={'eva:trash-2-outline'} sx={{ mr: 1 }} />
					Delete
				</MenuItem>
			</Popover>
		</>
	);
}
