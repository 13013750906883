import * as React from 'react';
import {
	CardContent,
	CardHeader,
	Typography,
	Divider,
	Stack,
	Box,
	FormControlLabel,
	Checkbox,
	FormControl,
	FormHelperText,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LocationOn } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import Status from '../../../components/request';
import { fDate, fDateTime } from '../../../utils/formatTime';

const style = {
	divider: {
		borderBottomWidth: 1.5,
	},
};

const RequestDetail = ({ request, formik, hideTnC, hideTestTotal }) => {
	const { id, candidate, tests, supplier, status, prefAssessmentDate, assessmentDate } = request;
	const theme = useTheme();

	const renderStatus = Boolean(status);

	return (
		<>
			{id && (
				<>
					<CardHeader title="ID" />
					<Divider sx={style.divider} />
					<CardContent>
						<Grid container spacing={2}>
							<Grid size={{ xs: 6 }}>
								<Typography variant="body1" gutterBottom>
									{id}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</>
			)}

			{renderStatus && (
				<>
					<CardHeader title="Status" />
					<Divider sx={style.divider} />
					<CardContent>
						<Grid container spacing={2}>
							<Grid size={{ xs: 6 }}>
								<Status status={status} clickable={false} />
							</Grid>
						</Grid>
					</CardContent>
				</>
			)}

			<CardHeader title="Candidate Information" />
			<Divider sx={style.divider} />
			<CardContent>
				<Grid container spacing={2}>
					<Grid size={{ xs: 6 }}>
						<Typography variant="subtitle1" gutterBottom>
							First Name
						</Typography>
						<Typography variant="body1" gutterBottom>
							{candidate?.firstname}
						</Typography>
					</Grid>
					<Grid size={{ xs: 6 }}>
						<Typography variant="subtitle1" gutterBottom>
							Last Name
						</Typography>
						<Typography variant="body1" gutterBottom>
							{candidate?.lastname}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>

			<CardHeader title="Contact" />
			<Divider sx={style.divider} />
			<CardContent>
				<Grid container spacing={2}>
					<Grid size={{ xs: 6 }}>
						<Typography variant="subtitle1" gutterBottom>
							Phone
						</Typography>
						<Typography variant="body1" gutterBottom>
							{candidate?.phone}
						</Typography>
					</Grid>
					<Grid size={{ xs: 6 }}>
						<Typography variant="subtitle1" gutterBottom>
							Email
						</Typography>
						<Typography variant="body1" gutterBottom>
							{candidate?.email}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>

			<CardHeader title="Address" />
			<Divider sx={style.divider} />
			<CardContent>
				<Grid container spacing={2}>
					<Grid size={{ xs: 6 }}>
						<Typography variant="subtitle1" gutterBottom>
							Address
						</Typography>
						<Typography variant="body1" gutterBottom>
							{candidate?.addrLine}
						</Typography>
					</Grid>
					<Grid size={{ xs: 6 }}>
						<Typography variant="subtitle1" gutterBottom>
							Suburb
						</Typography>
						<Typography variant="body1" gutterBottom>
							{candidate?.addrSuburb}
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid size={{ xs: 6 }}>
						<Typography variant="subtitle1" gutterBottom>
							Postcode
						</Typography>
						<Typography variant="body1" gutterBottom>
							{candidate?.addrPostcode}
						</Typography>
					</Grid>
					<Grid size={{ xs: 6 }}>
						<Typography variant="subtitle1" gutterBottom>
							State
						</Typography>
						<Typography variant="body1" gutterBottom>
							{candidate?.addrState}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>

			{candidate?.employer && (
				<>
					<CardHeader title="Employer" />
					<Divider sx={style.divider} />
					<CardContent>
						<Grid container spacing={2}>
							<Grid size={{ xs: 6 }}>
								<Typography variant="body1" gutterBottom>
									{candidate?.employer}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</>
			)}

			{assessmentDate ? (
				<>
					<CardHeader title="Assessment Date" />
					<Divider sx={style.divider} />
					<CardContent>
						<Grid container spacing={2}>
							<Grid size={{ xs: 6 }}>
								<Typography variant="body1" gutterBottom>
									{fDateTime(assessmentDate)}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</>
			) : (
				prefAssessmentDate && (
					<>
						<CardHeader title="Preferred Assessment Date" />
						<Divider sx={style.divider} />
						<CardContent>
							<Grid container spacing={2}>
								<Grid size={{ xs: 6 }}>
									<Typography variant="body1" gutterBottom>
										{fDate(prefAssessmentDate)}
									</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</>
				)
			)}

			<CardHeader title="Testing Centre" />
			<Divider sx={style.divider} />
			<CardContent>
				<Grid size={{ xs: 12 }}>
					<Typography variant="h6">{supplier?.name}</Typography>
				</Grid>
				<Stack spacing={1} sx={{ mt: 2 }}>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<LocationOn color="action" />
						<Typography variant="body1">{supplier?.address}</Typography>
					</Box>
				</Stack>
			</CardContent>

			<CardHeader title="Tests" />
			<Divider sx={style.divider} />
			<CardContent>
				<Stack spacing={2}>
					{tests.map((test) => (
						<Stack key={test.id} direction="row" justifyContent="space-between">
							<Typography variant="body1">{test.name}</Typography>
							<Typography variant="body1">{`$${test.price?.toFixed(2) || '0.00'}`}</Typography>
						</Stack>
					))}
					{!hideTestTotal && (
						<>
							<Box sx={{ height: 10 }} />
							<Stack direction="row" justifyContent="space-between">
								<Typography variant="subtitle1" fontWeight="bold">
									Subtotal
								</Typography>
								<Box sx={{ textAlign: 'right' }}>
									<Typography variant="subtitle1" sx={{ color: theme.palette.primary.main }} fontWeight="bold">
										{`$${tests.reduce((sum, test) => sum + (test.price || 0), 0).toFixed(2)}`}
									</Typography>
								</Box>
							</Stack>
							<Stack direction="row" justifyContent="space-between">
								<Typography variant="subtitle1" fontWeight="bold">
									GST (10%)
								</Typography>
								<Box sx={{ textAlign: 'right' }}>
									<Typography variant="subtitle1" sx={{ color: theme.palette.primary.main }} fontWeight="bold">
										{`$${(tests.reduce((sum, test) => sum + (test.price || 0), 0) * 0.1).toFixed(2)}`}
									</Typography>
								</Box>
							</Stack>
							<Stack direction="row" justifyContent="space-between">
								<Typography variant="subtitle1" fontWeight="bold">
									Total (inc. GST)
								</Typography>
								<Box sx={{ textAlign: 'right' }}>
									<Typography variant="subtitle1" sx={{ color: theme.palette.primary.main }} fontWeight="bold">
										{`$${(tests.reduce((sum, test) => sum + (test.price || 0), 0) * 1.1).toFixed(2)}`}
									</Typography>
								</Box>
							</Stack>
						</>
					)}
				</Stack>
			</CardContent>

			{!hideTnC && (
				<CardContent sx={{ pt: 0 }}>
					<FormControl component="fieldset" variant="standard" error={Boolean(formik.errors.tncAccepted)}>
						<FormHelperText sx={{ visibility: formik.errors.tncAccepted ? 'visible' : 'hidden' }}>
							{formik.errors.tncAccepted || ' '}
						</FormHelperText>
						<FormControlLabel
							control={
								<Checkbox
									name="tncAccepted"
									onChange={(e) => {
										formik.setFieldValue('tncAccepted', e.target.checked);
									}}
								/>
							}
							label={
								<Box>
									I have read and agree to the {''}
									<a href="https://ausrehab.com/pes-terms-and-conditions/" target="_blank" rel="noreferrer">
										Terms and Conditions
									</a>
								</Box>
							}
						/>
					</FormControl>
				</CardContent>
			)}
		</>
	);
};

export default RequestDetail;
