import * as React from 'react';
import { CardContent, CardHeader, Divider, Stack, Box, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LocationOn } from '@mui/icons-material';

const RequestDetailSkeleton = () => {
	return (
		<>
			<CardHeader title={<Skeleton width={100} />} />
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					<Grid size={{ xs: 6 }}>
						<Skeleton width="80%" height={20} />
					</Grid>
				</Grid>
			</CardContent>

			<CardHeader title={<Skeleton width={120} />} />
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					<Grid size={{ xs: 6 }}>
						<Skeleton variant="rectangular" width={80} height={20} />
					</Grid>
				</Grid>
			</CardContent>

			<CardHeader title={<Skeleton width={180} />} />
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					{[...Array(2)].map((_, index) => (
						<Grid key={index} size={{ xs: 6 }}>
							<Skeleton width="60%" height={20} />
							<Skeleton width="80%" height={24} />
						</Grid>
					))}
				</Grid>
			</CardContent>

			<CardHeader title={<Skeleton width={100} />} />
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					{[...Array(2)].map((_, index) => (
						<Grid key={index} size={{ xs: 6 }}>
							<Skeleton width="60%" height={20} />
							<Skeleton width="80%" height={24} />
						</Grid>
					))}
				</Grid>
			</CardContent>

			<CardHeader title={<Skeleton width={140} />} />
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					{[...Array(4)].map((_, index) => (
						<Grid key={index} size={{ xs: 6 }}>
							<Skeleton width="60%" height={20} />
							<Skeleton width="80%" height={24} />
						</Grid>
					))}
				</Grid>
			</CardContent>

			<CardHeader title={<Skeleton width={120} />} />
			<Divider />
			<CardContent>
				<Grid size={{ xs: 12 }}>
					<Skeleton width="50%" height={24} />
				</Grid>
				<Stack spacing={1} sx={{ mt: 2 }}>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<LocationOn color="action" />
						<Skeleton width="60%" height={24} />
					</Box>
				</Stack>
			</CardContent>

			<CardHeader title={<Skeleton width={100} />} />
			<Divider />
			<CardContent>
				<Stack spacing={2}>
					{[...Array(3)].map((_, index) => (
						<Stack key={index} direction="row" justifyContent="space-between">
							<Skeleton width="50%" height={24} />
							<Skeleton width="30%" height={24} />
						</Stack>
					))}
				</Stack>
			</CardContent>
		</>
	);
};

export default RequestDetailSkeleton;
