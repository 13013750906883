import { useState, useEffect } from 'react';
import {
	Box,
	Button,
	CardContent,
	IconButton,
	CardHeader,
	Divider,
	Dialog,
	List,
	ListItem,
	LinearProgress,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { MuiFileInput } from 'mui-file-input';
import { Controller, useForm } from 'react-hook-form';

import Iconify from '../components/iconify';
import {
	useGetRequestReportsQuery,
	useLazyGetRequestReportQuery,
	useUploadRequestReportMutation,
	useDeleteRequestReportMutation,
} from '../slices/api';
import Request from '../utils/request';
import toDataUri from '../utils/toDataUri';

const style = {
	divider: {
		borderBottomWidth: 1.5,
	},
};

const RequestReports = ({ request, isUser }) => {
	const [reportDeleteConfirmDialogOpen, setReportDeleteConfirmDialogOpen] = useState(null);
	const [selectedReport, setSelectedReport] = useState(null);
	const [reportTabOpened, setReportTabOpened] = useState(false);
	const [uploadRequestReport, { isLoading: isUploadingReport }] = useUploadRequestReportMutation();
	const [deleteRequestReport, { isLoading: isDeletingReport, isSuccess: isDelReportSuccess }] =
		useDeleteRequestReportMutation();

	const onSubmit = async (data) => {
		if (data.file) {
			const dataUri = await toDataUri(data.file);
			const base64Encoded = dataUri.split(',')[1];

			uploadRequestReport({
				requestId: request.id,
				data: base64Encoded,
				name: data.file.name,
				type: data.file.type,
			});
		}
	};

	const onDelButtonClick = (selectedReport) => {
		setReportDeleteConfirmDialogOpen(true);
		setSelectedReport(selectedReport);
	};

	const onDeleteReportConfirmDialogClose = () => {
		setReportDeleteConfirmDialogOpen(null);
	};

	const {
		control,
		reset,
		handleSubmit,
		formState: { isSubmitSuccessful },
	} = useForm({
		defaultValues: {
			file: null,
		},
	});

	const { data: reports = [], isFetching: isFetchingRequestReports } = useGetRequestReportsQuery(request.id);
	const [trigger, { data, isFetching: isFetchingRequestReport }] = useLazyGetRequestReportQuery();

	useEffect(() => {
		if (isSubmitSuccessful) {
			reset({ file: null });
		}

		if (isDelReportSuccess) {
			setReportDeleteConfirmDialogOpen(null);
		}
	}, [reset, isSubmitSuccessful, isDelReportSuccess]);

	if (isFetchingRequestReports || isFetchingRequestReport || isUploadingReport) {
		return <LinearProgress />;
	}

	const onReportView = (reportName) => {
		setReportTabOpened(false);
		trigger({
			requestId: request.id,
			reportName,
		});
	};

	const onReportDeleteConfirm = () => {
		deleteRequestReport({
			requestId: request.id,
			reportName: selectedReport,
		});
	};

	if (data?.url && !reportTabOpened) {
		setReportTabOpened(true);
		window.open(data.url, '_blank');
	}

	return (
		<>
			<CardHeader title="Reports" />
			<Divider sx={style.divider} />
			<CardContent>
				<Grid container spacing={2}>
					<Grid size={{ md: 'auto' }}>
						<List>
							{reports.map((report, index) => {
								const reportName = Request.getReportName(report.Key);

								return (
									<ListItem
										key={index}
										sx={{
											pl: 0,
										}}
										secondaryAction={
											<IconButton
												edge="end"
												aria-label="delete"
												onClick={() => {
													onDelButtonClick(reportName);
												}}
											>
												<Iconify
													icon={'eva:trash-2-outline'}
													sx={{
														color: (theme) => theme.palette.error.main,
													}}
												/>
											</IconButton>
										}
									>
										<Typography
											level="h3"
											sx={{
												':hover': {
													cursor: 'pointer',
													color: (theme) => theme.palette.primary.main,
												},
											}}
											onClick={() => {
												onReportView(reportName);
											}}
										>
											{reportName}
										</Typography>
									</ListItem>
								);
							})}
						</List>
					</Grid>
					{isUser ? null : (
						<Grid size={{ md: 12 }}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Controller
									control={control}
									render={({ field, fieldState }) => {
										return (
											<MuiFileInput
												{...field}
												placeholder="Select your report"
												helperText={fieldState.invalid ? 'File is invalid' : ''}
												error={fieldState.invalid}
												InputProps={{
													inputProps: {
														accept: Request.acceptedReportFileType,
													},
													startAdornment: <Iconify icon={'iconamoon:attachment-light'} />,
												}}
												clearIconButtonProps={{
													title: 'Remove',
													children: <Iconify icon={'iconamoon:close-bold'} />,
												}}
											/>
										);
									}}
									name="file"
								/>
								<Box>
									<Button type="submit" variant="contained" sx={{ mt: 2 }}>
										Upload
									</Button>
								</Box>
							</form>
						</Grid>
					)}
				</Grid>
			</CardContent>
			{/* report delete confirm */}
			<Dialog
				open={Boolean(reportDeleteConfirmDialogOpen)}
				onClose={onDeleteReportConfirmDialogClose}
				title={'Warning'}
			>
				<Grid container spacing={2} sx={{ p: 2 }}>
					<Grid size={{ md: 12 }}>
						<Typography variant="subtitle2">{`Confirm to delete report ${selectedReport}`}</Typography>
					</Grid>
					<Grid size={{ md: 4 }}>
						<Button loading={isDeletingReport} variant="outlined" color="primary" onClick={onReportDeleteConfirm}>
							<span>Confirm</span>
						</Button>
					</Grid>
					<Grid size={{ md: 4 }}>
						<Button color="error" variant="outlined" onClick={onDeleteReportConfirmDialogClose}>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Dialog>
		</>
	);
};

export default RequestReports;
