import { Dialog, DialogTitle, DialogContent, Button, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme } from '@mui/material/styles';

const SuccessAlert = ({ open, title, subtitle, onClose }) => {
	const theme = useTheme();

	return (
		<Dialog open={open} fullWidth>
			<DialogTitle sx={{ textAlign: 'center' }}>
				<CheckCircleOutlineIcon sx={{ fontSize: 64, color: theme.palette.success.dark }} />
				<Typography variant="h6">{title}</Typography>
			</DialogTitle>
			<DialogContent style={{ textAlign: 'center' }}>
				<Typography variant="subtitle2">{subtitle}</Typography>
				<Button variant="contained" color={theme.palette.main} sx={{ mt: 4 }} onClick={onClose}>
					Ok
				</Button>
			</DialogContent>
		</Dialog>
	);
};

export default SuccessAlert;
