import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Base64 } from 'js-base64';

import { baseUrl } from '../utils/config';
import tokenManager from '../utils/tokenManager';
import { resetAuth } from './auth';
import { setError } from './error';

/*
  when query result is still in cache, baseQuery will not be called to
  refetch.
*/
const baseQuery = fetchBaseQuery({
	baseUrl,
	prepareHeaders: async (headers) => {
		const token = await tokenManager.getAccessTokenSilently()();

		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}

		return headers;
	},
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
	const result = await baseQuery(args, api, extraOptions);

	const status = result?.error?.status;

	if (status >= 500 && status <= 599) {
		api.dispatch(
			setError({
				error: result.error.data,
				status,
			}),
		);
	}

	if (status === 400) {
		api.dispatch(
			setError({
				error: result.error.data,
				status,
			}),
		);
	}

	if (status === 401) {
		api.dispatch(resetAuth());
	}

	if (status === 403) {
		api.dispatch(
			setError({
				error: "You're Not authorised to perform this operation.",
				status,
			}),
		);
	}

	return result;
};

// TODO: invalidate cached data with particular id not the entire cached data
export const ausrehabApi = createApi({
	reducerPath: 'ausrehabApi',
	tagTypes: ['Request', 'EmailTemplates', 'Tests', 'Suppliers', 'Users'],
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getRequests: builder.query({
			query: () => ({
				url: 'requests',
			}),
			providesTags: ['Request'],
			transformResponse: (response) => {
				return response
					.filter((res) => res.candidate && !res.isArchived)
					.map((res) => {
						return {
							...res,
							candidateName: `${res.candidate.firstname} ${res.candidate.lastname}`,
						};
					});
			},
		}),
		getRequest: builder.query({
			query: (id) => ({
				url: `requests/${id}`,
			}),
			providesTags: ['Request'],
		}),
		getRequestReports: builder.query({
			query: (id) => ({
				url: `requests/${id}/reports`,
			}),
			providesTags: ['request-reports'],
		}),
		uploadRequestReport: builder.mutation({
			query: (payload) => {
				const { requestId, data, name, type } = payload;

				return {
					url: `requests/${requestId}/reports`,
					method: 'POST',
					body: {
						data,
						name,
						type,
					},
				};
			},
			invalidatesTags: ['request-reports'],
		}),
		deleteRequestReport: builder.mutation({
			query: (payload) => {
				const { requestId, reportName } = payload;

				return {
					url: `requests/${requestId}/reports`,
					method: 'DELETE',
					params: {
						report_name: reportName,
					},
				};
			},
			invalidatesTags: ['request-reports'],
		}),
		getRequestReport: builder.query({
			query: (args) => {
				const { requestId, reportName } = args;
				return {
					url: `requests/${requestId}/reports/${reportName}`,
				};
			},
			providesTags: ['Request'],
		}),
		addRequest: builder.mutation({
			invalidatesTags: ['Request'],
			query: (payload) => {
				payload.id = undefined;

				return {
					url: 'requests',
					method: 'POST',
					body: payload,
				};
			},
		}),
		delRequest: builder.mutation({
			query: (id) => ({
				url: `requests/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Request'],
		}),
		updateRequest: builder.mutation({
			query: (payload) => {
				return {
					url: `requests/${payload.id}`,
					method: 'PUT',
					body: payload,
				};
			},
			invalidatesTags: ['Request'],
		}),
		updateRequestAssessmentDate: builder.mutation({
			query: (payload) => {
				return {
					url: `requests/${payload.id}/assessmentDate`,
					method: 'PUT',
					body: {
						assessmentDate: payload.assessmentDate,
					},
				};
			},
			invalidatesTags: ['Request'],
		}),
		payRequest: builder.mutation({
			query: (payload) => {
				const { requestId, items } = payload;

				return {
					url: 'payments',
					method: 'POST',
					body: {
						requestId,
						items,
					},
				};
			},
		}),
		cancelRequest: builder.mutation({
			query: (payload) => {
				return {
					url: `requests/${payload.id}/cancel`,
					method: 'PUT',
				};
			},
			invalidatesTags: ['Request'],
		}),
		getSupplier: builder.query({
			query: (id) => ({
				url: `suppliers/${id}`,
			}),
			providesTags: ['Suppliers'],
		}),
		getSuppliers: builder.query({
			async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
				if (_arg === undefined) {
					const allSuppliers = await fetchWithBQ({
						url: 'suppliers',
					});
					return allSuppliers;
				}

				const { location, testIds } = _arg;

				const {
					data: {
						coordinates: [lng, lat],
					},
				} = await fetchWithBQ({
					url: 'locations',
					params: {
						location,
					},
				});

				const data = await fetchWithBQ({
					url: 'suppliers',
					params: {
						lng,
						lat,
						testIds,
						// should return full list
						// limit: 3,
					},
				});

				return data;
			},
			providesTags: ['Suppliers'],
		}),
		delSupplier: builder.mutation({
			query: (id) => ({
				url: `suppliers/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Suppliers'],
		}),
		updateSupplier: builder.mutation({
			async queryFn(payload, _queryApi, _extraOptions, baseQuery) {
				const { address } = payload;

				const {
					data: {
						coordinates: [lng, lat],
					},
				} = await baseQuery({
					url: 'locations',
					params: {
						location: address,
					},
				});

				const data = await baseQuery({
					url: `suppliers/${payload.id}`,
					method: 'PUT',
					body: {
						...payload,
						longitude: lng,
						latitude: lat,
					},
				});

				return data;
			},
			invalidatesTags: ['Suppliers'],
		}),
		addSupplier: builder.mutation({
			async queryFn(payload, _queryApi, _extraOptions, baseQuery) {
				const { address } = payload;

				const {
					data: {
						coordinates: [lng, lat],
					},
				} = await baseQuery({
					url: 'locations',
					params: {
						location: address,
					},
				});

				const data = await baseQuery({
					url: 'suppliers',
					method: 'POST',
					body: {
						...payload,
						longitude: lng,
						latitude: lat,
					},
				});

				return data;
			},
			invalidatesTags: ['Suppliers'],
		}),
		geocoding: builder.query({
			query: (args) => {
				const { location } = args;

				return {
					url: 'locations',
					params: {
						location,
					},
				};
			},
		}),
		getEmailTemplates: builder.query({
			query: () => ({
				url: 'email-templates',
			}),
			providesTags: ['EmailTemplates'],
		}),
		getEmailTemplate: builder.query({
			query: (id) => ({
				url: `email-templates/${id}`,
			}),
			transformResponse: (response) => {
				let bodyHtml;

				try {
					bodyHtml = Base64.decode(response.bodyHtml);
				} catch (err) {
					console.error(`${response.id} email template not encoded correctly`);
				}

				return {
					...response,
					bodyHtml,
				};
			},
			providesTags: ['EmailTemplates'],
		}),
		updateEmailTemplate: builder.mutation({
			query: ({ id, bodyHtml, name, subject }) => {
				return {
					url: `email-templates/${id}`,
					method: 'PUT',
					body: {
						bodyHtml: Base64.encode(bodyHtml),
						name,
						subject,
					},
				};
			},
			invalidatesTags: ['EmailTemplates'],
		}),
		addEmailTemplate: builder.mutation({
			query: ({ bodyHtml, name, subject }) => {
				return {
					url: 'email-templates',
					method: 'POST',
					body: {
						bodyHtml: Base64.encode(bodyHtml),
						name,
						subject,
					},
				};
			},
			invalidatesTags: ['EmailTemplates'],
		}),
		delEmailTemplate: builder.mutation({
			query: (id) => ({
				url: `email-templates/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['EmailTemplates'],
		}),
		getTest: builder.query({
			query: (id) => ({
				url: `tests/${id}`,
			}),
			providesTags: ['Tests'],
		}),
		getTests: builder.query({
			query: () => ({
				url: 'tests',
			}),
			providesTags: ['Tests'],
		}),
		delTest: builder.mutation({
			query: (id) => ({
				url: `tests/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Tests'],
		}),
		addTest: builder.mutation({
			query: (payload) => {
				return {
					url: 'tests',
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: ['Tests'],
		}),
		updateTest: builder.mutation({
			query: ({ id, ...payload }) => {
				return {
					url: `tests/${id}`,
					method: 'PUT',
					body: payload,
				};
			},
			invalidatesTags: ['Tests'],
		}),
		syncNewUser: builder.mutation({
			query: () => {
				return {
					url: 'postLogin',
					method: 'POST',
				};
			},
		}),
		getUsers: builder.query({
			query: () => ({
				url: 'users',
			}),
			providesTags: ['Users'],
		}),
		getUser: builder.query({
			query: (id) => ({
				url: `users/${id}`,
			}),
			providesTags: ['Users'],
		}),
		updateUser: builder.mutation({
			query: (payload) => {
				return {
					url: `users/${payload.id}`,
					method: 'PUT',
					body: payload,
				};
			},
			invalidatesTags: ['Users'],
		}),
		getRoles: builder.query({
			query: () => ({
				url: 'roles',
			}),
		}),
	}),
});

export const {
	useGetRequestQuery,
	useGetRequestsQuery,
	useGetSuppliersQuery,
	useGetSupplierQuery,
	useGetTestsQuery,
	useGetTestQuery,
	useGeocodingQuery,
	useGetRequestReportsQuery,
	useGetEmailTemplatesQuery,
	useGetEmailTemplateQuery,
	useGetUsersQuery,
	useGetUserQuery,
	useGetRolesQuery,
	// mutations
	useDelRequestMutation,
	useUpdateRequestMutation,
	useUpdateRequestAssessmentDateMutation,
	useAddRequestMutation,
	useAddSupplierMutation,
	usePayRequestMutation,
  useCancelRequestMutation,
	useDelSupplierMutation,
	useUpdateSupplierMutation,
	useUploadRequestReportMutation,
	useDeleteRequestReportMutation,
	useUpdateEmailTemplateMutation,
	useAddEmailTemplateMutation,
	useDelEmailTemplateMutation,
	useAddTestMutation,
	useDelTestMutation,
	useUpdateTestMutation,
	useSyncNewUserMutation,
	useUpdateUserMutation,
	// lazy queries
	useLazyGetRequestReportQuery,
	useLazyGetRequestQuery,
} = ausrehabApi;
